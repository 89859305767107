<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">预览日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="机构名称" class="searchboxItem searchboxItems">
              <span class="itemLabel"  >机构名称:</span>
              <el-input
                  v-model="compName"
                  type="text"
                  size="small"
                  placeholder="请输入机构名称"
                  clearable
                  style="flex:1"
              />
            </div>
            
             <div title="观看时长" class="searchboxItem searchboxItems">
              <span class="itemLabel" style="min-width:6rem">观看时长超</span>
              <el-input v-model="watchTime" size="small" onkeyup="value=value.replace(/\D|^0/g,'')">
                <template slot="append">分钟</template>
              </el-input>
            </div>
             <div title="当天连续观看视频超" class="searchboxItem searchboxItems">
              <span class="itemLabel" style="min-width:9rem">当天连续观看视频超</span>
              <el-input v-model="count" size="small" onkeyup="value=value.replace(/\D|^0/g,'')">
                <template slot="append">个</template>
              </el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:5px">
           <div title="日期" class="searchboxItem searchboxItems">
              <span class="itemLabel">日期:</span>
              <el-date-picker
                  clearable
                  size="small"
                  v-model="searchDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="起始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
           
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                  style="margin-left: 20px"
                  class="bgc-bv"
                  round
                  @click="getData()"
              >查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                  label="账号"
                  align="left"
                  width="100px"
                  show-overflow-tooltip
                  prop="adminName"
              />
              <el-table-column
                  label="姓名"
                  align="left"
                  width="150px"
                  show-overflow-tooltip
                  prop="fullname"
              />
              <el-table-column
                  label="电话"
                  align="left"
                  width="100px"
                  show-overflow-tooltip
                  prop="mobile"
              />
              <el-table-column
                  label="机构名称"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
              />
              <el-table-column
                  label="课程名称"
                  align="left"
                  show-overflow-tooltip
                  prop="courseName"
              />
              <el-table-column
                  label="课件名称"
                  align="left"
                  show-overflow-tooltip
                  prop="kpointName"
              />
              <el-table-column
                  label="开始时间"
                  width="100px"
                  align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.startTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="结束时间"
                  width="100px"
                  align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.endTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="观看时长"
                  width="130px"
                  align="center"
                >
                <template slot-scope="scope">
                  {{ getTime(scope.row.watchTime) || "--" }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "WatchLogList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      //查询条件
      compName: "", //机构名称
      searchDate: "", // 日期
      watchTime: "", //观看时长(分钟)
      count: "", //每天连续观看超过的数量
    };
  },
  created() {
    this.getTableHeight();
  },
  computed: {},
  methods: {
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 +2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (h != '00' ? h + "时" : '')  + ( m != '00' ? m + "分" : '') + ( s != '00' ?  s + "秒" :'' )|| "";
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.searchDate) {
        params.startDate = this.searchDate[0];
        params.endDate = this.searchDate[1];
      }
      if (this.watchTime) {
        params.watchTime = this.watchTime;
      }
      if (this.count) {
        params.count = this.count;
      }
      this.doFetch({
        url: "/biz/course/watch/log/page",
        params,
        pageNum
      });
    },
  },
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.dialog-switch {
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
}
.el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>